const publicApi = {
  createUser: (data: CreateUserRequest) => http.post<A<CreateUserResponse>>('/gateway/public/user', data),
  getSymbols: () => http.get<A<SymbolRes>>('/public/allTradingSymbols'),
  getFeeInfo: () => http.get<A<FeeRes>>('/public/tradeFee'),
  getCurrency: () => http.get<A<CurrencyRes>>('/public/assetInfo'),
  getKLine: (params: KLineReq) => http.get<KLineRes>('/gateway/public/klines', { params }),
  getTickerInfo: () => http.get<A<TickerRes>>('/public/tickerInfo'),
  getHealth: () => http.get<any>('/gateway/public/status'),
  getChainConfig: () => http.get<A<{ data: Record<keyof ChainConfig, string>[] }>>('/gateway/public/chain'),
}

export default publicApi

export interface FeeItem {
  level: string
  makerFeeRate: string
  takerFeeRate: string
}

interface FeeRes {
  vipFeeRateDetails: FeeItem[]
}

export interface ChainConfig {
  nativeCurrencyDecimals: number
  nativeCurrencySymbol: string
  nativeCurrencyName: string
  id: number
  name: string
  publicRpcUrl: string
  explorerName: string
  explorerUrl: string
  vaultAddress: string
}

export interface TickerSymbol {
  symbol: string
  baseAssetId: number
  baseAssetName: string
  baseAssetOnChainDecimal: number
  baseAssetPrecision: number
  isSpotTradingAllowed: boolean
  maxBaseTradingSize: number
  minBaseTradingSize: number
  quoteAssetId: number
  quoteAssetName: string
  quoteAssetOnChainDecimal: number
  quoteAssetPrecision: number
  status: string
  takerFeeDiscount: number
  makerFeeDiscount: number
}

export interface TickerRes {
  symbols: TickerSymbol[]
}

export interface KLineReq {
  symbol: string
  interval: string
  startTime?: string
  endTime?: string
  limit?: number
}

export interface CreateUserRequest {
  signMessage: string
  signature: string
}

export interface CreateUserResponse {
  address: string
  id: number
  key: string
  passphrase: string
  secret: string
  level: string
  makeFeeRate: number
  takeFeeRate: number
  status: string
  vesselKey: string
}

export interface SymbolItem {
  base: string
  baseId: number
  baseOnChainDecimal: number
  closeTime: number
  closePrice: string
  count: number
  highPrice: number
  lastPrice: number
  lowPrice: number
  openPrice: number
  openTime: number
  quote: string
  quoteId: number
  quoteVolume: number
  quoteOnChainDecimal: number
  symbol: string
  type: 'SPOT'
  volume: number
  isSpotTradingAllowed: boolean
}

export interface SymbolRes {
  results: SymbolItem[]
}

export interface CurrencyItem {
  assetId: number
  assetName: string
  address: string
  onChainDecimal: number
  scaleK: number
  canDepositFrontend: boolean
  canWithdraw: boolean
  depositLimit: number
  depositPrecision: number
  isToken: boolean
  maxWithdrawSize: number
  minWithdrawSize: number
  price: number
}

export interface CurrencyRes {
  assets: CurrencyItem[]
}

export interface KLineItem {
  openTime: number
  openPrice: string
  highPrice: string
  lowPrice: string
  closePrice: string
  volume: string
  trades: number
  closeTime: number
  quoteVolume: string
  takerBuyBaseVolume: string
  takerBuyQuoteVolume: string
}

export interface KLineRes {
  klines: KLineItem[]
}
