export interface VesselKey {
  privateKey: string
  publicKey: string
  walletAddress: string
  walletType: string
}

export interface ApiKey {
  key: string
  passphrase: string
  secret: string
  walletAddress: string
  walletType: string
}

interface StorageKey {
  vessel: VesselKey
  api: ApiKey
}

export const useKey = createGlobalState(() => {
  const keyStorage = useStorage<Record<string, StorageKey>>('VESSEL_KEY', {})
  const vesselKey = ref<VesselKey>()
  const apiKey = ref<ApiKey>()
  const isRemember = ref(true)
  const isGettingKey = ref(false)
  const isExportUserKey = ref(false)
  const step = ref(0)

  const isSigned = computed(() => !!vesselKey.value && !!apiKey.value)

  const { openModal } = useModal()

  async function getVesselKey() {
    if ((location.pathname === '/login' || isSigned.value) && (location.pathname !== '/key')) {
      return
    }
    isGettingKey.value = true
    step.value = 0
    openModal('signVesselKey')
  }

  function saveKey(address: string) {
    if (!vesselKey.value || !apiKey.value) {
      return
    }
    keyStorage.value = {
      ...keyStorage.value,
      [address]: {
        vessel: vesselKey.value,
        api: apiKey.value,
      },
    }
  }

  function clearKey(address: string) {
    if (keyStorage.value[address]) {
      delete keyStorage.value[address]
    }
    vesselKey.value = null
    apiKey.value = null
  }

  function initKey(address: string) {
    if (apiKey.value && vesselKey.value) {
      return true
    }
    if (!keyStorage.value[address]) {
      vesselKey.value = null
      apiKey.value = null
      return false
    }
    const keyPairs = keyStorage.value[address]
    apiKey.value = keyPairs.api
    vesselKey.value = keyPairs.vessel
    return true
  }
  return {
    apiKey,
    vesselKey,
    getVesselKey,
    isRemember,
    isSigned,
    step,
    saveKey,
    clearKey,
    initKey,
    isGettingKey,
    isExportUserKey,
  }
})
