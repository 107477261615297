import { useAccount, useConfig } from '@wagmi/vue'
import { readContract } from '@wagmi/core'
import { formatUnits } from 'viem'
import type { AssetItem, UserInfo } from '@/service/modules/user'
import type { StatsUserInfo, UserPreference } from '~/service/modules/stats'
import vaultAbi from '~/abi/vault.json'
import { getWagmiConfig } from '~/lib/wagmi'

interface InvitationCode {
  isValid: boolean
  code: string
  isNewCode: boolean
  boundAddress: string
}

const defaultInvitation = {
  isValid: false,
  code: '',
  isNewCode: false,
  boundAddress: '',
}

export type AssetItemWithClaimable = AssetItem & { claimable?: number }

export const useUser = createGlobalState(() => {
  const userInfo = ref<UserInfo>()
  const userStatsInfo = ref<StatsUserInfo>()
  const { address, isConnected } = useAccount()
  const initAddress = ref('')
  const assetList = ref<AssetItem[]>([])
  const assetListWithClaimable = ref<AssetItemWithClaimable[]>([])
  const preference = ref<UserPreference>()
  const config = useConfig()
  const { currencyList } = useSymbol()

  const { run: getAssetList, isLoading: isLoadingAsset } = useHttp(vesselApi.user.getAsset)

  // invitation
  const invitation = useStorage<InvitationCode>('invitationCode', defaultInvitation)

  function clearInvitation() {
    invitation.value = defaultInvitation
  }

  function initStatsUserInfo() {
    vesselApi.stats.getStatsUserInfo().then((res) => {
      userStatsInfo.value = res.data
    })
  }

  function initUserInfo() {
    getUserAsset()
    initPreference()
    return vesselApi.user.getUserInfo().then((res) => {
      userInfo.value = res.data
      return res.data
    })
  }

  function initPreference() {
    vesselApi.stats.getPreference().then((res) => {
      preference.value = res.data
    })
  }

  async function updatePreference() {
    await vesselApi.stats.updatePreference(preference.value)
  }

  function clearUserInfo() {
    preference.value = null
    userInfo.value = null
  }

  async function getClaimableFromAssetName(assetName: string) {
    const assetItem = currencyList.value.find(i => i.assetName === assetName)
    if (!assetItem?.address) {
      return 0
    }
    const data = await getClaimableFromChain(assetItem.assetId)
    return formatUnits(data, assetItem.onChainDecimal)
  }

  function getUserAsset() {
    getAssetList().then(async (res) => {
      assetList.value = res.data.assets
      assetListWithClaimable.value = assetList.value.map(i => ({ ...i, claimable: 0 }))
    })
  }
  async function getUserAssetWithClaim() {
    for (const item in assetListWithClaimable.value) {
      assetListWithClaimable.value[item] = {
        ...assetListWithClaimable.value[item],
        claimable: await getClaimableFromAssetName(assetListWithClaimable.value[item].assetName),
      }
    }
  }

  return {
    userInfo,
    address,
    isConnected,
    invitation,
    clearInvitation,
    initUserInfo,
    clearUserInfo,
    assetList,
    getUserAsset,
    userStatsInfo,
    isLoadingAsset,
    preference,
    updatePreference,
    assetListWithClaimable,
    initStatsUserInfo,
    initAddress,
    getUserAssetWithClaim,
  }
})
