<script setup lang="ts">
import { useDisconnect } from '@wagmi/vue'
import metamaskImg from '~/assets/image/wallet/metamask.png'
import walletConnectImg from '~/assets/image/wallet/walletConnect.png'
import trustImg from '~/assets/image/wallet/trust.png'
import rainbowImg from '~/assets/image/wallet/rainbow.png'
import coinbaseImg from '~/assets/image/wallet/coinbase.png'
import okxImg from '~/assets/image/wallet/okx.png'
import rabbyImg from '~/assets/image/wallet/rabby.png'
import type { WalletConnectType } from '@/store/connector'

const { modal, openModal, closeModal } = useModal()
const connector = useConnector()
const message = useMessage()
const { isMobile } = useResponsive()
const { disconnectAsync } = useDisconnect()

interface WalletList {
  name: string
  icon: string
  walletType?: WalletConnectType
  disabled?: boolean
}

const walletList: WalletList[] = [
  {
    name: 'Metamask',
    icon: metamaskImg,
    walletType: 'metaMaskSDK',
  },
  {
    name: 'OKX Wallet',
    icon: okxImg,
    walletType: 'okxwallet',
  },
  {
    name: 'Rabby Wallet',
    icon: rabbyImg,
    walletType: 'rabby',
  },
  {
    name: 'WalletConnect',
    icon: walletConnectImg,
    walletType: 'walletConnect',
  },
  {
    name: 'Trust',
    icon: trustImg,
    disabled: true,
  },
  {
    name: 'Rainbow',
    icon: rainbowImg,
    disabled: true,
  },
  // {
  //   name: 'Coinbase',
  //   icon: coinbaseImg,
  //   disabled: true,
  // },
]

async function connect(wallet?: WalletList) {
  if (wallet.disabled) {
    return
  }
  if (!connector.isConnected.value) {
    // openModal('connecting')
    if (['okxwallet', 'rabby'].includes(wallet.walletType)) {
      if (!window[wallet.walletType]) {
        message.error('Wallet not found.')
        return
      }
    }
    closeModal('chooseProvider')
    connector.connectWallet(wallet.walletType).then(() => {

    }).catch((error) => {
      disconnectAsync().then(() => {
        console.log(error.message)
      })
    }).finally(() => {
      // closeModal('connecting')
    })
  }
}
</script>

<template>
  <v-modal v-model:show="modal.chooseProvider" title="Connect Your Wallet">
    <div class="text-grey1">
      Please choose your wallet from the supported options.
    </div>
    <div v-if="!isMobile" class="wallet-list mt-0.32 gap-0.16">
      <div
        v-for="item in walletList" :key="item.name"
        class="h-0.64 w-2 flex items-center gap-x-0.08 rd-0.08 px-0.16 transition-all"
        :class="{
          'hover:bg-primary cursor-pointer bg-black3': !item.disabled,
          'wallet-disabled before:inset-0 before:bg-#31333A/30': item.disabled,
        }"
        @click="connect(item)"
      >
        <img :src="item.icon" alt="" class="h-0.48 w-0.48">
        <div
          class="text-0.14 font-700 font-dm" :class="{
            'text-white2': !item.disabled,
            'text-grey1': item.disabled,
          }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div v-else class="mt-0.32 flex flex-col gap-0.14">
      <div
        v-for="item in walletList" :key="item.name"
        class="h-0.56 w-full flex items-center justify-between gap-x-0.08 rd-0.08 px-0.16 transition-all"
        :class="{
          'hover:bg-primary cursor-pointer bg-black3 ': !item.disabled,
          'cursor-not-allowed bg-#31333A': item.disabled,
        }"
        @click="connect(item)"
      >
        <div
          class="text-0.14 font-700 font-dm" :class="{
            'text-white2': !item.disabled,
            'text-grey1': item.disabled,
          }"
        >
          {{ item.name }}
        </div>
        <div class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1353 0.601594C12.61 -0.200531 14.3907 -0.200532 15.8653 0.601594L24.4143 5.25179C26.0071 6.11823 26.9987 7.78633 26.9987 9.5996V18.4004C26.9987 20.2137 26.0071 21.8818 24.4143 22.7482L15.8653 27.3984C14.3907 28.2005 12.61 28.2005 11.1353 27.3984L2.58637 22.7482C0.993508 21.8818 0.00195313 20.2137 0.00195313 18.4004L0.00195312 9.5996C0.00195312 7.78633 0.993507 6.11823 2.58637 5.25179L11.1353 0.601594Z" fill="#FCFCFD" />
          </svg>
          <img :src="item.icon" alt="" class="absolute inset-0 m-auto h-0.22 w-0.22">
        </div>
      </div>
    </div>
  </v-modal>
</template>

<style scoped lang="scss">
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.wallet-disabled {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;

  }
}
</style>
